<template>
  <!-- 第三方订单列表页面 -->
  <div class="padding24 color_white" id="third_Party_order">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div>
        <div class="top">
          <a-form layout="inline">
            <a-form-item label="订单编号">
              <a-input
                v-model="params.orderNo"
                placeholder="请输入订单编号"
                @pressEnter="searchClick"
              ></a-input>
            </a-form-item>
            <a-form-item label="用户手机号">
              <a-input
                v-model="params.userName"
                placeholder="请输入用户手机号"
                @pressEnter="searchClick"
              ></a-input>
            </a-form-item>
            <a-form-item label="商品名称">
              <a-input
                v-model="params.name"
                placeholder="请输入商品名称"
                @pressEnter="searchClick"
              ></a-input>
            </a-form-item>
            <a-form-item label="订单状态">
              <a-select
                v-model="params.orderStatus"
                :getPopupContainer="
                  (triggerNode) => triggerNode.parentNode || document.body
                "
                style="width: 100px"
                @change="changeorderStatus"
              >
                <a-select-option
                  v-for="item in orderStatusArr"
                  :key="item.key"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
            
            <a-form-item label="下单时间">
              <a-range-picker
                :value="rangePicker"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                @change="onChange"
              />
            </a-form-item>
            <a-form-item>
              <a-button
                icon="search"
                type="primary"
                @click="searchClick"
                :disabled="isDisableSearch"
              >
                搜索
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button icon="redo" type="primary" @click="resetClick">
                重置
              </a-button>
            </a-form-item>
          </a-form>
          <a-form layout="inline">
            <a-form-item>
              <a-button
                v-if="isShowOrderExport"
                icon="export"
                type="primary"
                @click="exportClick"
              >
                导出订单
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <!-- 表格 -->
        <a-table
          :columns="listenerOrderColumns"
          :data-source="tableData"
          :pagination="false"
          :loading="tableLoading"
        />
        <MyPagination
          :count="count"
          :pageNo="params.pageNo"
          @showSizeChangeFn="showSizeChangeFn"
          v-show="!tableLoading"
        />
        <!-- 返回顶部 -->
        <a-back-top :target="targetFn" :visibilityHeight="100" />
      </div>
    </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import { listenerOrderColumns } from './constants'
import { getCompayOrdersAPI, exportCompanyAPI } from '@/request/api/orderformManage'
import MyPagination from '@/components/pagination/MyPagination'
import { codeFn } from '@/utils/tools'
export default {
  components: { MyPagination },
  created() {
    this.fetchData()
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.orderStatus.forEach((item) =>
      this.orderStatusArr.push(item)
    );
    this.isShowOrderExport = codeFn('listenerOrderExport')
  },
  data() {
    return {
      spinning: false, // 全局loading
      tip: '',
      params: {
        pageNo: 1,
        pageSize: 20,
        orderNo: '',
        userName: '',
        name: '', // 商品名称
        orderStatus: 0, // 订单状态的默认值
        startTime: '',
        endTime: '',
        thirdPartyChannel: 9
      },
      count: 0,
      orderStatusArr: [{ key: 0, value: '全部状态' }], //订单状态的数组
      rangePicker: [], // 下单日期数组
      isDisableSearch: false, // 搜索是否禁用
      tableLoading: true,
      listenerOrderColumns,
      tableData: [],
      isShowOrderExport: false, // 同步订单是否隐藏
      form: {},
    }
  },
  methods: {
    moment,
    // 商品类型改变的回调
    changeOrderType(value) {
      this.pageNo = 1;
      this.orderType = value;
      this.fetchData();
    },
    // 订单状态改变的回调
    changeorderStatus(value) {
      this.pageNo = 1;
      this.params.orderStatus = value;
      this.fetchData();
    },
    // 下单日期改变的回调
    onChange(date, dateString) {
      if (dateString[0] !== '') {
        this.params.startTime = dateString[0];
        this.params.endTime = dateString[1];
        this.rangePicker = [
          moment(dateString[0], "YYYY-MM-DD HH:mm:ss"),
          moment(dateString[1], "YYYY-MM-DD HH:mm:ss"),
        ];
      } else {
        this.params.startTime = ''
        this.params.endTime = ''
        this.rangePicker = [];
      }
    },
    // 搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.fetchData();
    },
    // 重置按钮
    resetClick() {
      this.params = this.$options.data().params
      this.receive = ''
      this.rangePicker = [];
    },
    
    // 导出订单按钮
    async exportClick() {
      this.tip = '订单导出中'
      this.spinning = true
      const params = this.params
      const { code, data } = await exportCompanyAPI(params)
      this.spinning = false
      if (code !== 200) return
      window.location.href = data
      this.spinning = false
      this.$message.success('导出订单成功')
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.params.pageNo = current
      this.params.pageSize = pageSize
      this.fetchData()
    },
    // 获取第三方订单列表
    async fetchData() {
      this.tableLoading = true
      const params = this.params
      const { code, data } = await getCompayOrdersAPI(params)
      this.tableLoading = false
      this.tableData = []
      if (code !== 200) return
      this.count = data.count;
      data.data.forEach((item) => {
        item.key = item.orderNo;
        item.payTime = item.payTime === null ? '——' : item.payTime
      });
      this.tableData = data.data
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#third_Party_order");
    },
  },
};
</script>
 
<style lang="less" scoped>
#third_Party_order {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  .top_line {
    margin-bottom: 20px;
    display: flex;
    span {
      margin-right: 20px;
      /deep/.ant-input {
        width: 220px;
      }
      /deep/.ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }
      /deep/.ant-calendar-picker-input {
        margin-left: 10px;
        width: auto;
      }
      /deep/.ant-calendar-picker {
        width: 400px !important;
      }
    }
    .ant-btn {
      margin-right: 20px;
    }
  }
}
</style>